import React from "react"
import { Link } from "gatsby"

// SEO
import SEO from "../components/SEO"

// Components
import Layout from "../components/Layout"

class Index extends React.Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Thanks for your submission"
          description="We've recorded your result. Our team will be in touch."
        />

        <div className="uk-section uk-section-default">
          <div className="uk-container uk-container-small uk-text-center">
            <div
              className="uk-flex uk-flex-middle uk-flex-center"
              uk-height-viewport="expand: true"
            >
              <div className="uk-width-4-5@s uk-width-2-3@l">
                <h2 className="uk-text-bold uk-h3">Thank you</h2>
                <p>
                  We got your submission. We'll be in touch as soon as possible
                  with next steps.
                </p>
                <Link
                  className="uk-button uk-button-large uk-button-primary uk-border-pill uk-margin-bottom"
                  to="/"
                >
                  Back to home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Index
